.App {
  text-align: center;
}

.navbar-toggler-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2825, 165, 233, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e') !important;
}

.ant-typography .landing-link {
  color: rgb(125 211 252);
}

.ant-typography .landing-link &:hover {
  color: rgb(240 249 255);
}

.ant-menu-horizontal {
  border: 0 !important;
}
.ant-menu-item-selected::after {
  border-bottom: 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
input[type='number'],
.ant-input-number-input,
select:focus,
textarea {
  font-size: 16px !important;
}

.ant-divider {
  border-block-start-color: rgb(14 165 233);
}
